let songElements = document.getElementsByClassName('song');

for( var i = 0; i < songElements.length; i++ ){
	/*
		Ensure that on mouseover, CSS styles don't get messed up for active songs.
	*/
	songElements[i].addEventListener('mouseover', function(){
		this.style.backgroundColor = '#00A0FF';

		this.querySelectorAll('.song-meta-data .song-title')[0].style.color = '#FFFFFF';
		this.querySelectorAll('.song-meta-data .song-artist')[0].style.color = '#FFFFFF';

		if( !this.classList.contains('amplitude-active-song-container') ){
			this.querySelectorAll('.play-button-container')[0].style.display = 'block';
		}

		this.querySelectorAll('.song-duration')[0].style.color = '#FFFFFF';
	});

	/*
		Ensure that on mouseout, CSS styles don't get messed up for active songs.
	*/
	songElements[i].addEventListener('mouseout', function(){
		this.style.backgroundColor = '#FFFFFF';
		this.querySelectorAll('.song-meta-data .song-title')[0].style.color = '#272726';
		this.querySelectorAll('.song-meta-data .song-artist')[0].style.color = '#607D8B';
		this.querySelectorAll('.play-button-container')[0].style.display = 'none';
		this.querySelectorAll('.song-duration')[0].style.color = '#607D8B';
	});

	/*
		Show and hide the play button container on the song when the song is clicked.
	*/
	songElements[i].addEventListener('click', function(){
		this.querySelectorAll('.play-button-container')[0].style.display = 'none';
	});
}

/*
	Initializes AmplitudeJS
*/
Amplitude.init({
	continue_next: true,
	callbacks: {
		song_change: function(){
		}
	},
	"songs": [
		{
			"name": "Let's Meet by the River",
			"artist": "Annette Martin",
			"album": "Let's Meet by the River",
			"url": "../songs/01 - Let's Meet by the River.mp3",
			"cover_art_url": "../album-art/lmbtr.jpg",
			"album_url": "<a href='albums/lets_meet_by_the_river.zip' download>Download Album</a>"
		},
		{
			"name": "He Picked Me Up",
			"artist": "Annette Martin",
			"album": "Let's Meet by the River",
			"url": "../songs/02 - He Picked Me Up.mp3",
			"cover_art_url": "../album-art/lmbtr.jpg",
			"album_url": "<a href='albums/lets_meet_by_the_river.zip' download>Download Album</a>"
		},
		{
			"name": "Help Me Stand",
			"artist": "Annette Martin",
			"album": "Let's Meet by the River",
			"url": "../songs/03 - Help Me Stand.mp3",
			"cover_art_url": "../album-art/lmbtr.jpg",
			"album_url": "<a href='albums/lets_meet_by_the_river.zip' download>Download Album</a>"
		},
		{
			"name": "Busy People",
			"artist": "Annette Martin",
			"album": "Let's Meet by the River",
			"url": "../songs/04 - Busy People.mp3",
			"cover_art_url": "../album-art/lmbtr.jpg",
			"album_url": "<a href='albums/lets_meet_by_the_river.zip' download>Download Album</a>"
		},
		{
			"name": "I'll Drop My Anchor",
			"artist": "Annette Martin",
			"album": "Let's Meet by the River",
			"url": "../songs/05 - I'll Drop My Anchor.mp3",
			"cover_art_url": "../album-art/lmbtr.jpg",
			"album_url": "<a href='albums/lets_meet_by_the_river.zip' download>Download Album</a>"
		},
		{
			"name": "I Want Us to be Together in Heaven",
			"artist": "Annette Martin",
			"album": "Let's Meet by the River",
			"url": "../songs/06 - I Want Us to be Together in Heaven.mp3",
			"cover_art_url": "../album-art/lmbtr.jpg",
			"album_url": "<a href='albums/lets_meet_by_the_river.zip' download>Download Album</a>"
		},
		{
			"name": "I've Got More to go to Heaven For",
			"artist": "Annette Martin",
			"album": "Let's Meet by the River",
			"url": "../songs/07 - I've Got More to go to Heaven For.mp3",
			"cover_art_url": "../album-art/lmbtr.jpg",
			"album_url": "<a href='albums/lets_meet_by_the_river.zip' download>Download Album</a>"
		},
		{
			"name": "Amazing Grace",
			"artist": "Annette Martin",
			"album": "Let's Meet by the River",
			"url": "../songs/08 - Amazing Grace.mp3",
			"cover_art_url": "../album-art/lmbtr.jpg",
			"album_url": "<a href='albums/lets_meet_by_the_river.zip' download>Download Album</a>"
		},
		{
			"name": "Thank you Mama for Praying for Me",
			"artist": "Annette Martin",
			"album": "Let's Meet by the River",
			"url": "../songs/09 - Thank you Mama for Praying for Me.mp3",
			"cover_art_url": "../album-art/lmbtr.jpg",
			"album_url": "<a href='albums/lets_meet_by_the_river.zip' download>Download Album</a>"
		},
		{
			"name": "Welcome to Heaven",
			"artist": "Annette Martin",
			"album": "Let's Meet by the River",
			"url": "../songs/10 - Welcome to Heaven.mp3",
			"cover_art_url": "../album-art/lmbtr.jpg",
			"album_url": "<a href='albums/lets_meet_by_the_river.zip' download>Download Album</a>"
		},
		{
			"name": "I'll Be Waiting",
			"artist": "Annette Martin",
			"album": "I'll Be Waiting",
			"url": "../songs/01 - I'll Be Waiting.mp3",
			"cover_art_url": "../album-art/waiting.jpg",
			"album_url": "<a href='albums/ill_by_waiting.zip' download>Download Album</a>"
		},
		{
			"name": "Thank You Lord",
			"artist": "Annette Martin",
			"album": "I'll Be Waiting",
			"url": "../songs/02 - Thank You Lord.mp3",
			"cover_art_url": "../album-art/waiting.jpg",
			"album_url": "<a href='albums/ill_by_waiting.zip' download>Download Album</a>"
		},
		{
			"name": "Child of the King",
			"artist": "Annette Martin",
			"album": "I'll Be Waiting",
			"url": "../songs/03 - Child of the King.mp3",
			"cover_art_url": "../album-art/waiting.jpg",
			"album_url": "<a href='albums/ill_by_waiting.zip' download>Download Album</a>"
		},
		{
			"name": "Closer to You",
			"artist": "Annette Martin",
			"album": "I'll Be Waiting",
			"url": "../songs/04 - Closer to You.mp3",
			"cover_art_url": "../album-art/waiting.jpg",
			"album_url": "<a href='albums/ill_by_waiting.zip' download>Download Album</a>"
		},
		{
			"name": "Without Him",
			"artist": "Annette Martin",
			"album": "I'll Be Waiting",
			"url": "../songs/05 - Without Him.mp3",
			"cover_art_url": "../album-art/waiting.jpg",
			"album_url": "<a href='albums/ill_by_waiting.zip' download>Download Album</a>"
		},
		{
			"name": "Born to Serve the Lord",
			"artist": "Annette Martin",
			"album": "I'll Be Waiting",
			"url": "../songs/06 - Born to Serve the Lord.mp3",
			"cover_art_url": "../album-art/waiting.jpg",
			"album_url": "<a href='albums/ill_by_waiting.zip' download>Download Album</a>"
		},
		{
			"name": "God's Bigger Than What's the Matter",
			"artist": "Annette Martin",
			"album": "I'll Be Waiting",
			"url": "../songs/07 - God's Bigger Than What's the Matter.mp3",
			"cover_art_url": "../album-art/waiting.jpg",
			"album_url": "<a href='albums/ill_by_waiting.zip' download>Download Album</a>"
		},
		{
			"name": "My Prayer",
			"artist": "Annette Martin",
			"album": "I'll Be Waiting",
			"url": "../songs/08 - My Prayer.mp3",
			"cover_art_url": "../album-art/waiting.jpg",
			"album_url": "<a href='albums/ill_by_waiting.zip' download>Download Album</a>"
		},
		{
			"name": "Sweeter Each Day",
			"artist": "Annette Martin",
			"album": "I'll Be Waiting",
			"url": "../songs/09 - Sweeter Each Day.mp3",
			"cover_art_url": "../album-art/waiting.jpg",
			"album_url": "<a href='albums/ill_by_waiting.zip' download>Download Album</a>"
		},
		{
			"name": "He's in the Midst",
			"artist": "Annette Martin",
			"album": "I'll Be Waiting",
			"url": "../songs/10 - He's in the Midst.mp3",
			"cover_art_url": "../album-art/waiting.jpg",
			"album_url": "<a href='albums/ill_by_waiting.zip' download>Download Album</a>"
		},
		{
			"name": "Here I Am",
			"artist": "Annette Martin",
			"album": "I'll Be Waiting",
			"url": "../songs/11 - Here I Am.mp3",
			"cover_art_url": "../album-art/waiting.jpg",
			"album_url": "<a href='albums/ill_by_waiting.zip' download>Download Album</a>"
		},
		{
			"name": "Down in the Valley",
			"artist": "Annette Martin",
			"album": "I'll Be Waiting",
			"url": "../songs/12 - Down in the Valley.mp3",
			"cover_art_url": "../album-art/waiting.jpg",
			"album_url": "<a href='albums/ill_by_waiting.zip' download>Download Album</a>"
		},
		{
			"name": "The Sun's Coming Up",
			"artist": "Annette Martin",
			"album": "I'll Be Waiting",
			"url": "../songs/13 - The Sun's Coming Up.mp3",
			"cover_art_url": "../album-art/waiting.jpg",
			"album_url": "<a href='albums/ill_by_waiting.zip' download>Download Album</a>"
		}
	]
});